// src/pages/DashboardPage.js
import React, { useState } from "react";
import "./CoursesPage.css";
import CourseCard from "../../components/CourseCard/CourseCard";

const Courses = () => {
  const [filters, setFilters] = useState({
    authors: "",
    level: [],
    price: [],
    topic: "",
    rating: "",
  });

  const clearFilters = () => {
    setFilters({
      authors: "",
      level: [],
      price: [],
      topic: "",
      rating: "",
    });
  };

  const courses = [
    {
      name: "Jacob Jones",
      expertise: "R Expert",
      experience: 16,
      sessions: 20,
      rating: 4.5,
      imageUrl:
        "https://media.istockphoto.com/id/1300512215/photo/headshot-portrait-of-smiling-ethnic-businessman-in-office.jpg?s=1024x1024&w=is&k=20&c=tq1C4HoZraH5szwBZqUf7sGVp7EPO-VB8PVbAPjagqY= ",
    },
    {
      name: "Jane Cooper",
      expertise: "Python Developer",
      experience: 10,
      sessions: 15,
      rating: 5.0,
      imageUrl:
        "https://media.istockphoto.com/id/1318858332/photo/headshot-portrait-of-smiling-female-employee-posing-in-office.jpg?s=1024x1024&w=is&k=20&c=HThNP7cTwpPjRgylneAIW2VnkHDInJ27qnpjzcC4f-Y=",
    },
    {
      name: "Devon Lane",
      expertise: "Front-End Developer",
      experience: 6,
      sessions: 8,
      rating: 3.5,
      imageUrl:
        "https://media.istockphoto.com/id/1806222821/photo/portrait-of-a-young-financier-businessman-close-up-joyful-man-smiling-and-looking-to-the-side.jpg?s=1024x1024&w=is&k=20&c=MkodidNAbJw_ntHCsDOg8xnnHq4PL2tiYLXFHQB7uTg=",
    },
    // Add more courses as needed
  ];

  return (
    <div className="course-page">
      <aside className="filters">
        {/* <h3>Authors</h3>
        <select
          value={filters.authors}
          onChange={(e) => setFilters({ ...filters, authors: e.target.value })}
        >
          <option value="">Select authors to filter!</option>
          
        </select> */}

        <h3>Level</h3>
        <label className="filter-option">
          <input type="checkbox" id="all-level" value="all" />
          All Level
        </label>
        <label className="filter-option">
          <input type="checkbox" id="beginner" value="beginner" />
          Beginner
        </label>
        <label className="filter-option">
          <input type="checkbox" id="intermediate" value="intermediate" />
          Intermediate
        </label>
        <label className="filter-option">
          <input type="checkbox" id="advanced" value="advanced" />
          Advanced
        </label>

        {/* <h3>Price</h3>
        <label className="filter-option">
          <input type="checkbox" id="free" value="free" />
          Free
        </label>
        <label className="filter-option">
          <input type="checkbox" id="paid" value="paid" />
          Paid
        </label> */}

        <h3>Topic course</h3>
        <select
          value={filters.topic}
          onChange={(e) => setFilters({ ...filters, topic: e.target.value })}
        >
          <option value="">Select topics to filter!</option>
          {/* Add topic options */}
        </select>

        <h3>Course Ratings</h3>
        <label className="filter-option">
          <input type="radio" id="4.5-up" name="rating" value="4.5" />
          ⭐⭐⭐⭐⭐ 4.5 & up
        </label>
        <label className="filter-option">
          <input type="radio" id="4.0-up" name="rating" value="4.0" />
          ⭐⭐⭐⭐ 4.0 & up
        </label>
        <label className="filter-option">
          <input type="radio" id="3.5-up" name="rating" value="3.5" />
          ⭐⭐⭐⭐ 3.5 & up
        </label>
        <label className="filter-option">
          <input type="radio" id="3.0-up" name="rating" value="3.0" />
          ⭐⭐⭐ 3.0 & up
        </label>
      </aside>

      <main className="course-list">
        <header className="course-header">
          <h1>Find your best courses</h1>
          <div className="course-controls">
            <button>Sort</button>
            <select>
              <option>Most Reviews</option>
              <option>Highest Rated</option>
              <option>Newest</option>
            </select>
            <button className="clear-filters" onClick={clearFilters}>
              Clear Filters
            </button>
          </div>
        </header>

        <div className="courses">
          {/* <p>0 results</p>
          <div className="course-skeleton">
            <div className="skeleton"></div>
            <div className="skeleton"></div>
            <div className="skeleton"></div>
            <div className="skeleton"></div>
          </div> */}
          <div className="course-list">
            {courses.map((course, index) => (
              <CourseCard
                key={index}
                name={course.name}
                expertise={course.expertise}
                experience={course.experience}
                sessions={course.sessions}
                rating={course.rating}
                imageUrl={course.imageUrl}
              />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Courses;
