// src/components/ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const isAuthenticated = () => {
  // Check if user is logged in (you can use session storage, JWT tokens, etc.)
  // return localStorage.getItem('authToken') ? true : false;
  return true;
};

const ProtectedRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
