import React, { useEffect, useState } from "react";
import "./HomePage.css";
import { FaCalendarAlt } from "react-icons/fa";
import { MdOutlineAccessTime } from "react-icons/md";
import prof1 from "../../assets/images/prof1.jpg";
import prof2 from "../../assets/images/prof2.jpg";
import b32 from "../../assets/images/B-32.png";
import b46 from "../../assets/images/B-46.png";
import { MdComputer } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import Chart from "react-apexcharts";
import { PiTimerBold } from "react-icons/pi";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ReactPlayer from "react-player";

const HomePage = () => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Desktops",
        data: [30, 40, 35, 50, 49, 60, 70],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"],
      },
    },
  });

  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const [isInMeeting, setIsInMeeting] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(true);

  const handleJoinMeeting = () => {
    setIsInMeeting(!isInMeeting); // Trigger video screen
  };

  const [currentTime, setCurrentTime] = useState(40);

  const handleSeek = (seekTime) => {
    console.log(`Video was seeked to: ${seekTime} seconds`);
    setCurrentTime(seekTime);
  };

  const [messages, setMessages] = useState([
    {
      id: 1,
      sender: "You",
      text: "Hi Professor Foxx, can we talk about motion design?",
    },
    { id: 2, sender: "Robert Foxx", text: "Of course! What’s on your mind?" },
    {
      id: 3,
      sender: "You",
      text: "I’m confused about the principles of animation.",
    },
    // {
    //   id: 4,
    //   sender: "Robert Foxx",
    //   text: "No problem! Which principle do you want to discuss?",
    // },
    // { id: 5, sender: "You", text: "I don’t get easing functions." },
    // {
    //   id: 6,
    //   sender: "Robert Foxx",
    //   text: "Easing functions control the speed of animations.",
    // },
    // { id: 7, sender: "You", text: "Can you give a quick example?" },
    // {
    //   id: 8,
    //   sender: "Robert Foxx",
    //   text: "Sure! An 'ease-in' starts slow and speeds up.",
    // },
  ]);

  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      setMessages([
        ...messages,
        { id: messages.length + 1, sender: "You", text: newMessage },
      ]);
      setNewMessage(""); // Clear the input
    }
  };

  // const [staff, setStaff] = useState(true);
  const type = sessionStorage.getItem("type");

  console.log(type, "type");

  // const [isPlaying, setIsPlaying] = useState(false);
  // const [duration, setDuration] = useState(0);
  // const startTimestamp = 30;
  // let startsFrom = 5;
  // console.log(duration, "duration");
  // console.log(isPlaying, "isPlaying");

  // const handleDuration = (duration) => {
  //   setDuration(duration);
  //   console.log("gggggg");
  //   if (isPlaying === false && duration > startTimestamp) {
  //     setIsPlaying(true);
  //   }
  // };

  const [isPlaying, setIsPlaying] = useState(true);
  const [duration, setDuration] = useState(0);
  const startTimestamp = 30;

  console.log(isPlaying, "is playing");
  const handleDuration = (duration) => {
    setDuration(duration);
    console.log("Video Duration:", duration);
    // Set isPlaying to true only if duration is greater than startTimestamp
    if (!isPlaying && duration > startTimestamp) {
      setIsPlaying(true);
    }
  };

  const videoSrc =
    "https://kavin-test.s3.us-east-2.amazonaws.com/videoplayback.mp4";
  const startsFrom = 10;

  return (
    <>
      {type === "faculty" && (
        <div className="home-page-main-container">
          {!isInMeeting ? (
            <>
              <div className="home-page-left-container">
                <div className="welcome-text">Hello Robert Foxx!</div>

                <div className="upcoming-sessions-container">
                  <div className="section-title">Upcoming Sessions</div>
                  <div className="session-cards">
                    <div className="session-card">
                      <div className="session-details">
                        <div className="session-img-container">
                          <img
                            src={b32}
                            alt="Robert Fox"
                            className="profile-img"
                          />
                        </div>
                        <div className="session-info">
                          <div className="session-name-details">
                            <div className="session-name">Batch 32</div>
                            <div className="session-role">Motion Design</div>
                          </div>
                          <div className="session-datetime">
                            <div className="date">
                              <FaCalendarAlt /> 10 July, 2024
                            </div>
                            <div className="time">
                              <MdOutlineAccessTime /> 12:30 PM
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="session-footer">
                        <button
                          className="join-btn"
                          onClick={handleJoinMeeting}
                        >
                          Start Session
                        </button>
                        <div className="duration">
                          <PiTimerBold /> 1h 59min
                        </div>
                      </div>
                    </div>

                    <div className="session-card">
                      <div className="session-details">
                        <div className="session-img-container">
                          <img
                            src={b46}
                            alt="Wade Warren"
                            className="profile-img"
                          />
                        </div>
                        <div className="session-info">
                          <div className="session-name-details">
                            <div className="session-name">Batch 46</div>
                            <div className="session-role">Creo</div>
                          </div>
                          <div className="session-datetime">
                            <div className="date">
                              <FaCalendarAlt /> 15 July, 2024
                            </div>
                            <div className="time">
                              <MdOutlineAccessTime /> 01:00 PM
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="session-footer">
                        <button
                          className="join-btn"
                          onClick={handleJoinMeeting}
                        >
                          Start Session
                        </button>
                        <div className="duration">
                          <PiTimerBold /> 1h 30min
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="session-number-cards">
                  <div>
                    {/* <MdComputer /> */}
                    <div>
                      <div>
                        Active <br />
                        Sessions
                      </div>
                      <div>12</div>
                    </div>
                  </div>
                  <div>
                    {/* <MdComputer /> */}
                    <div>
                      <div>
                        Completed <br />
                        Sessions
                      </div>
                      <div>4</div>
                    </div>
                  </div>
                  <div>
                    {/* <MdComputer /> */}
                    <div>
                      <div>
                        Inactive <br />
                        Sessions
                      </div>
                      <div>0</div>
                    </div>
                  </div>
                </div>

                <div className="time-invested-container">
                  <div className="header">
                    <div>Time Invested</div>
                    <div>250 Min</div>
                  </div>
                  <div className="chart-container">
                    <Chart
                      options={chartData.options}
                      series={chartData.series}
                      type="line"
                      height={250}
                    />
                  </div>
                </div>
              </div>

              <div className="home-page-right-container">
                <div className="stats-container">
                  <div className="stats-card">
                    <MdComputer className="stats-icon" />
                    <div>
                      <div
                        className="stats-title"
                        style={{ textAlign: "center" }}
                      >
                        Total Courses
                      </div>
                      <div
                        className="stats-value"
                        style={{ textAlign: "center" }}
                      >
                        17
                      </div>
                    </div>
                  </div>
                  <div className="stats-card">
                    <FaUser className="stats-icon" />
                    <div>
                      <div
                        className="stats-title"
                        style={{ textAlign: "center" }}
                      >
                        Students Enrolled
                      </div>
                      <div
                        className="stats-value"
                        style={{ textAlign: "center" }}
                      >
                        86
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-courses-container">
                  <div className="my-courses-header">
                    <h3>My Courses</h3>
                    <button className="view-all-btn">View All</button>
                  </div>
                  <ul className="course-lists">
                    <li className="course-item">
                      Hospitality and Tourism Management
                    </li>
                    <li className="course-item">
                      Global Supply Chain Management
                    </li>
                    <li className="course-item">
                      Accounting and Payroll Admin
                    </li>
                    <li className="course-item">Computerized Accounting</li>
                    <li className="course-item">Office Administration</li>
                    <li className="course-item">Automation Technology</li>
                    <li className="course-item">CAD/CAM Technology</li>
                  </ul>
                </div>
              </div>
            </>
          ) : (
            <div className="meeting-page-container">
              <div className="meeting-container-header">
                <div className="back-button" onClick={handleJoinMeeting}>
                  <MdOutlineArrowBackIosNew
                    style={{ fontSize: "20px" }}
                    aria-label="Go Back"
                  />
                </div>
                <div className="meeting-details">
                  <div className="meeting-title">Motion Design</div>
                  <div className="meeting-host">Robert Foxx</div>
                </div>
              </div>

              <div className="meeting-container">
                <div className="video-container">
                  <ReactPlayer
                    url="https://kavin-test.s3.us-east-2.amazonaws.com/videoplayback.mp4"
                    controls
                    playing={isPlaying}
                    width="100%"
                    height="100%"
                    // onDuration={handleDuration}
                    // onReady={(player) => {
                    //   // Seek to the startTimestamp when the player is ready
                    //   player.seekTo(startTimestamp, "seconds");
                    // }}
                  />
                  {/* <HlsPlayer
                    url={videoSrc}
                    autoPlay
                    controls
                    onReady={(video) => {
                      video.currentTime = startsFrom;
                    }}
                    width="100%"
                    height="100%"
                  /> */}
                </div>

                <div className="chat-container">
                  <div className="chat-header">Session Chat</div>

                  <div className="chat-messages">
                    {messages.map((message) => (
                      <div
                        key={message.id}
                        className={`chat-message ${
                          message.sender === "You"
                            ? "chat-message-user"
                            : "chat-message-others"
                        }`}
                      >
                        <strong>{message.sender}:</strong> {message.text}
                      </div>
                    ))}
                  </div>

                  <div className="chat-input-container">
                    <input
                      type="text"
                      className="chat-input"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      placeholder="Send a message to everyone"
                    />
                    <button
                      className="chat-send-btn"
                      onClick={handleSendMessage}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {type === "student" && (
        <div className="home-page-main-container">
          {!isInMeeting ? (
            <>
              <div className="home-page-left-container">
                <div className="welcome-text">Welcome Vivek Devaraj! 👋</div>

                <div className="upcoming-sessions-container">
                  <div className="section-title">Upcoming Sessions</div>
                  <div className="session-cards">
                    <div className="session-card">
                      <div className="session-details">
                        <div className="session-img-container">
                          <img
                            src={prof1}
                            alt="Robert Fox"
                            className="profile-img"
                          />
                        </div>
                        <div className="session-info">
                          <div className="session-name-details">
                            <div className="session-name">Robert Fox</div>
                            <div className="session-role">Motion Design</div>
                          </div>
                          <div className="session-datetime">
                            <div className="date">
                              <FaCalendarAlt /> 10 July, 2024
                            </div>
                            <div className="time">
                              <MdOutlineAccessTime /> 12:30 PM
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="session-footer">
                        <button
                          className="join-btn"
                          onClick={handleJoinMeeting}
                        >
                          Start Session
                        </button>
                        <div className="duration">
                          <PiTimerBold /> 1h 59min
                        </div>
                      </div>
                    </div>

                    <div className="session-card">
                      <div className="session-details">
                        <div className="session-img-container">
                          <img
                            src={prof2}
                            alt="Wade Warren"
                            className="profile-img"
                          />
                        </div>
                        <div className="session-info">
                          <div className="session-name-details">
                            <div className="session-name">Wade Warren</div>
                            <div className="session-role">Web Developer</div>
                          </div>
                          <div className="session-datetime">
                            <div className="date">
                              <FaCalendarAlt /> 15 July, 2024
                            </div>
                            <div className="time">
                              <MdOutlineAccessTime /> 01:00 PM
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="session-footer">
                        <button
                          className="join-btn"
                          onClick={handleJoinMeeting}
                        >
                          Start Session
                        </button>
                        <div className="duration">
                          <PiTimerBold /> 1h 30min
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="time-invested-container">
                  <div className="header">
                    <div>Time Invested</div>
                    <div>250 Min</div>
                  </div>
                  <div className="chart-container">
                    <Chart
                      options={chartData.options}
                      series={chartData.series}
                      type="line"
                      height={250}
                    />
                  </div>
                </div>
              </div>

              <div className="home-page-right-container">
                <div className="stats-container">
                  <div className="stats-card">
                    <MdComputer className="stats-icon" />
                    <div>
                      <div
                        className="stats-title"
                        style={{ textAlign: "center" }}
                      >
                        Total Sessions
                      </div>
                      <div
                        className="stats-value"
                        style={{ textAlign: "center" }}
                      >
                        12
                      </div>
                    </div>
                  </div>
                  <div className="stats-card">
                    <MdComputer className="stats-icon" />
                    <div>
                      <div
                        className="stats-title"
                        style={{ textAlign: "center" }}
                      >
                        Cancelled Sessions
                      </div>
                      <div
                        className="stats-value"
                        style={{ textAlign: "center" }}
                      >
                        4
                      </div>
                    </div>
                  </div>
                </div>

                <div className="calendar-container">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </LocalizationProvider>
                  <div className="book-session">
                    <MdComputer className="session-icon" />
                    <div>Book your session</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            // <div className="meeting-page-container">
            //   {/* Header */}
            //   <div className="meeting-container-header">
            //     <div className="back-button">
            //       <MdOutlineArrowBackIosNew
            //         style={{ fontSize: "20px" }}
            //         aria-label="Go Back"
            //       />
            //     </div>
            //     <div className="meeting-details">
            //       <div className="meeting-title">Motion Design</div>
            //       <div className="meeting-host">Robert Foxx</div>
            //     </div>
            //   </div>

            //   {/* Main Content */}
            //   <div className="meeting-container">
            //     {/* Video Section */}
            //     <div className="video-container">
            //       <ReactPlayer
            //         url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
            //         controls
            //         onSeek={handleSeek}
            //         width="100%"
            //         height="100%"
            //       />
            //     </div>

            //     {/* Chat Section */}
            //     <div className="chat-container">
            //       <h3>Chat</h3>
            //       <p>Chat content goes here...</p>
            //     </div>
            //   </div>
            // </div>
            <div className="meeting-page-container">
              {/* Header */}
              <div className="meeting-container-header">
                <div className="back-button" onClick={handleJoinMeeting}>
                  <MdOutlineArrowBackIosNew
                    style={{ fontSize: "20px" }}
                    aria-label="Go Back"
                  />
                </div>
                <div className="meeting-details">
                  <div className="meeting-title">Motion Design</div>
                  <div className="meeting-host">Robert Foxx</div>
                </div>
              </div>

              <div className="meeting-container">
                <div className="video-container">
                  <ReactPlayer
                    url="https://kavin-test.s3.us-east-2.amazonaws.com/videoplayback.mp4"
                    controls
                    playing={true}
                    onSeek={handleSeek}
                    width="100%"
                    height="100%"
                  />
                </div>

                {/* Chat Section (Google Meet-like) */}
                <div className="chat-container">
                  <div className="chat-header">Session Chat</div>

                  {/* Chat messages */}
                  <div className="chat-messages">
                    {messages.map((message) => (
                      <div
                        key={message.id}
                        className={`chat-message ${
                          message.sender === "You"
                            ? "chat-message-user"
                            : "chat-message-others"
                        }`}
                      >
                        <strong>{message.sender}:</strong> {message.text}
                      </div>
                    ))}
                  </div>

                  {/* Chat input */}
                  <div className="chat-input-container">
                    <input
                      type="text"
                      className="chat-input"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      placeholder="Send a message to everyone"
                    />
                    <button
                      className="chat-send-btn"
                      onClick={handleSendMessage}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default HomePage;
