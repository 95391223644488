import React from "react";
import "./CourseCard.css";

const CourseCard = ({
  name,
  expertise,
  experience,
  sessions,
  rating,
  imageUrl,
}) => {
  return (
    <div className="course-card">
      <div className="card-image">
        <img
          src={imageUrl}
          alt={`${name}'s profile`}
          className="profile-image"
        />
        <div className="card-rating">
          <span>{rating}</span>
          <i className="fa fa-star"></i>
        </div>
      </div>
      <div className="card-body">
        <h3 className="course-name">{name}</h3>
        <p className="expertise">{expertise}</p>
        <div className="card-info">
          <p>
            <strong>Experience:</strong> {experience} Years
          </p>
          <p>
            <strong>Total Sessions:</strong> {sessions}
          </p>
        </div>
      </div>
      <div className="card-actions">
        <button className="details-btn">Details</button>
        <button className="book-now-btn">Enroll</button>
      </div>
    </div>
  );
};

export default CourseCard;
