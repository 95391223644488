import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Layout.css";
import { FaHome, FaUser, FaSignOutAlt } from "react-icons/fa";
import { MdClass } from "react-icons/md";
import { BiCollapseHorizontal } from "react-icons/bi";
import { TbLayoutSidebarLeftCollapseFilled } from "react-icons/tb";
import { TbLayoutSidebarRightCollapseFilled } from "react-icons/tb";
import Header from "../header/Header";

const Layout = ({ children }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/login");
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const type = sessionStorage.getItem("type");

  return (
    <div className="layout">
      <Header toggleSidebar={toggleSidebar} />

      <div className="main-container">
        <aside className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}>
          <nav>
            <ul>
              <li>
                <NavLink
                  to="/home"
                  className={({ isActive }) =>
                    isActive ? "active link" : "link"
                  }
                >
                  <FaHome /> <span className="link-text">Home</span>
                </NavLink>
              </li>
              {type === "student" && (
                <li>
                  <NavLink
                    to="/courses"
                    className={({ isActive }) =>
                      isActive ? "active link" : "link"
                    }
                  >
                    <MdClass /> <span className="link-text">Courses</span>
                  </NavLink>
                </li>
              )}
              <li
                onClick={() => {
                  handleLogout();
                  sessionStorage.clear();
                }}
                style={{ padding: "10px" }}
                className="sidebar-li"
              >
                <FaSignOutAlt />{" "}
                <span style={{ marginLeft: "10px" }} className="link-text">
                  Logout
                </span>
              </li>
              <li
                onClick={toggleSidebar}
                style={{ padding: "10px" }}
                className="sidebar-li"
              >
                {isSidebarCollapsed ? (
                  <TbLayoutSidebarLeftCollapseFilled />
                ) : (
                  <TbLayoutSidebarRightCollapseFilled />
                )}{" "}
                <span style={{ marginLeft: "10px" }} className="link-text">
                  Collapse
                </span>
              </li>
            </ul>
          </nav>
        </aside>
        <main className="content">{children}</main>
      </div>
    </div>
  );
};

export default Layout;
