import React from "react";
import "./Header.css";
import logo from "../../assets/images/betaaa header-2.png";

const Header = ({ toggleSidebar }) => {
  return (
    <header className="header">
      <div className="header-logo-container">
        <img src={logo} className="header-logo" alt="header_logo" />
      </div>
      {/* <h1>My App</h1> */}
      {/* <button className="sidebar-toggle" onClick={toggleSidebar}>
        <FaBars /> Collapse
      </button> */}
    </header>
  );
};

export default Header;
