import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/betaaa header-2.png";
import loginbanner from "../../assets/images/login.png";
import "./LoginPage.css";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === "vivekdevaraj@gmail.com") {
      sessionStorage.setItem("type", "student");
      navigate("/home");
    }
    if (email === "robertfoxx@gmail.com") {
      sessionStorage.setItem("type", "faculty");
      navigate("/home");
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-left-section">
          <div className="login-logo">
            <img src={logo} alt="UTech Logo" />
          </div>
          <h2 className="login-title">Welcome to Beta College</h2>
          <p className="login-subtitle">Sign into your account</p>
          <form onSubmit={handleLogin}>
            <div className="login-form-group">
              <input
                type="email"
                placeholder="Phone or Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="login-input"
              />
            </div>
            <div className="login-form-group">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="login-input"
              />
            </div>
            <button type="submit" className="login-form-button">
              Log In
            </button>
          </form>
          <a href="/forgot-password" className="login-forgot-password">
            Forgot password?
          </a>
        </div>
        <div className="login-right-section">
          <img
            src={loginbanner}
            alt="Illustration"
            className="login-illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
